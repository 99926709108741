<template>

<app-content :loading="is.loading">

	<app-content-head title="Data capture" back="Convention.Pages.Captures">

	</app-content-head>

	<app-content-body>

		<app-content-summary>

			<app-content-summary-item label="Name" :grow="true" :value="item.firstname + ' ' + item.lastname" />
			<app-content-summary-item label="E-mail" :value="item.email" v-if="item.email" />
			<app-content-summary-item label="Page" :value="item.page" v-if="item.page" />
			<app-content-summary-item label="Date" :value="item.date | localDate('MM/DD/YYYY h:mma')" />

		</app-content-summary>

		<app-content-box title="More information" icon="info" :not-padded="true">

			<app-table :empty="!$_.keys(item.data).length" emptyMessage="There is no additional information.">

				<app-table-head :layout="infoLayout">

					<app-table-column text="Question" />
					<app-table-column text="Answer" />

				</app-table-head>

				<app-table-body>

					<app-table-row :layout="infoLayout" v-for="(item, id) in item.data" :key="id">

						<app-table-cell-text :text="item.label" />
						<app-table-cell-text :text="item.value" />

					</app-table-row>

				</app-table-body>

			</app-table>

		</app-content-box>
		
	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/pages/access', 'read'),
			infoLayout: '200px auto',
			model: {
				id: false
			}
		}

	},

	validations: {
		model: {
		}
	}

}

</script>

<style scoped>

</style>